/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    right: 15px;
    bottom: 15px;
    background: #284864;
    color: #fff;
    transition: display 0.5s ease-in-out;
    z-index: 99999;
    transition: all ease-in-out 0.3s;
}

.back-to-top i {
    font-size: 24px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.back-to-top:hover {
    color: #fff;
    background: #fdc134;
}


/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background: linear-gradient(180deg, #f2f6fa 0%, #fff 100%);
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #213b52;
}

.section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}

.section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #fdc134;
    bottom: 0;
    left: calc(50% - 20px);
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background-color: #f6f9fb;
    min-height: 40px;
}

.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li + li {
    padding-left: 10px;
}

.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

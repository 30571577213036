#hero {
    width: 100%;
    /*height: 100vh;*/
    position: relative;
    overflow: hidden;
    padding: 140px 0 100px 0;
    background: #213b52;
    background: linear-gradient(180deg, #213b52 0%, #213c54 35%, #204564 100%);
}

#hero h1 {
    margin: 0 0 15px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #d2e0ed;
    font-family: "Poppins", sans-serif;
}

#hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 22px;
}

#hero .btn-get-started {
    color: #213b52;
    border-radius: 50px;
    padding: 8px 35px 10px 35px;
    border: 2px solid #fdc134;
    transition: all ease-in-out 0.3s;
    display: inline-block;
    background: #fdc134;
}

#hero .btn-get-started:hover {
    background: transparent;
    color: #fff;
}

@media (max-width: 991px) {
    #hero .hero-img {
        text-align: center;
    }
    #hero .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #hero {
        -moz-text-align-last: center;
        text-align-last: center;
    }
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }
    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero .hero-img img {
        width: 80%;
    }
}
